.page404{
  position: relative;
  height: 100vh;
}
.page404 img{
  width: 100%;
}
.page404 p{
  font-size: .32rem;
  line-height: .44rem;
  color: #bbb;
  display: block;
  text-align: center;
}
.page404 div{
  position: absolute;
  width: 100%;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}