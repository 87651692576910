.material{
  overflow: hidden;
}
.material header{
  padding: 0;
  padding-left: .4rem;
  height: .96rem;
  white-space: nowrap;
  overflow-x: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 10;
}
.material header::after{
  content: '';
  position: fixed;
  left: 0;
  top: .93rem;
  right: 0;
  height: 0;
  border-bottom: .02rem solid #ddd;
}
.material header::-webkit-scrollbar{width:0;height:1px}
.material header > span{
  font-size: .32rem;
  line-height: .48rem;
  height: .96rem;
  box-sizing: border-box;
  display: inline-block;
  padding: .24rem .32rem;
  color: #333;
}
.material header > span:first-child{
  padding-left: 0;
}
.material header > span:first-child::after{
  left: 0;
}
.material header .active{
  color: #DCAA65;
  position: relative;
}
.material header .active::after{
  content: '';
  position: absolute;
  left: .32rem;
  bottom: 0;
  right: .32rem;
  height: .08rem;
  background: #DCAA65;
  border-radius: .04rem;
}
.material .topImg{
  position: fixed;
  right: 0;
  top: 0;
  background: linear-gradient(90deg,hsl(0, 0%, 100%, .2) 0%, #fff 60%, #fff 100%);
  width: 1.32rem;
  height: .96rem;
}
.material .topImg img{
  float: right;
  height: .48rem;
  margin-top: .24rem;
  margin-right: .24rem;
}
.material .condition{
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top: .96rem;
  background: #fff;
  z-index: 10;
  line-height: .96rem;
  font-size: .24rem;
  color: #aaa;
  padding: 0 .4rem;
  border-bottom: .01rem solid hsl(0, 0%, 0%, .1);
}
.material .condition div{
  flex: 1;
  text-align: center;
}
.material .condition div:first-child{
  text-align: left;
}
.material .condition div:last-child{
  text-align: right;
}
.material .condition div.active{
  color: #555;
}
.material .sort{
  height: .72rem;
  margin: .32rem 0 0 .4rem;
  background: #F2F3F4;
  font-size: .28rem;
  text-align: center;
  color: #333;
  padding: 0 .6rem 0 .32rem;
  line-height: .72rem;
  position: relative;
  border-radius: .1rem;
}
.material .sort img{
  width: .32rem;
  height: .32rem;
  position: absolute;
  top: .2rem;
  right: .27rem;
  transition: all .2s linear;
}
.material .sortType{
  display: block;
  position: absolute;
  left: 0;
  top: 1.04rem;
  border-radius: 0 0 .1rem .1rem;
  height: 0;
  overflow: hidden;
  transition: all .2s linear;
  z-index: 12;
}
.material .sortType span{
  display: block;
  height: .72rem;
  margin: 0 0 0 .4rem;
  background: #F2F3F4;
  font-size: .28rem;
  text-align: center;
  color: #333;
  padding: 0 .6rem 0 .32rem;
  line-height: .72rem;
  border-top: .01rem solid #ddd;
}
.material main{
  margin-top: 1.92rem;
  height: calc(100vh - 1.92rem);
  overflow-y: auto;
  -webkit-overflow-scrolling : touch;
}
.material .listItem{
  margin: 0 .4rem;
  border-bottom: .01rem solid #f2f2f2;
  padding: .32rem 0;
}
.material .listItem .left{
  float: left;
}
.material .listItem p{
  display: block;
  max-width: 3.6rem;
  font-size: .36rem;
  line-height: .48rem;
  max-height: 1.44rem;
  color: #333;
}
.material .listItem .shareNum{
  display: block; 
  color: #999;
  font-size: .28rem;
  line-height: .48rem;
  margin-top: .34rem;
}
.material .shareNum span{
  font-size: .32rem;
}
.material .listItem .image{
  width: 2.8rem;
  height: 1.8rem;
  float: right;
  /* margin-right: .4rem; */
  overflow: hidden;
  border-radius: .1rem;
  position: relative;
}
.material .image img{
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.material .bottomText{
  color: #999;
  font-size: .24rem;
  line-height: .5rem;
  text-align: center;
  margin-top: .2rem;
}