.detail{
  padding: .24rem;
  width: 100%;
  overflow: hidden;
}
.detail .content img{
  max-width: 100%;
}
.detail h2{
  font-size: .4rem;
  line-height: .55rem;
  color: #333;
}
.detail .timeNum{
  font-size: .28rem;
  line-height: .38rem;
  color: #999;
  margin-top: .24rem;
  margin-bottom: .4rem;
}
.detail .timeNum a{
  color: #1677D2;
}
.detail .timeNum .shareNumber{
  margin-left: .56rem;
}
.detail .main .content{
  padding-bottom: 1.5rem;
}
.detail .topUser{
  display: flex;
  border-radius: .1rem;
  padding: .32rem .60rem .30rem .32rem;
  border: .01rem solid #ddd;
  margin-bottom: .4rem;
}
.detail .topUser .left{
  float: left;
}
.detail .topUser .left img {
  width: 1.44rem;
  height: 1.44rem;
  border-radius: 50%;
}
.detail .topUser .left a {
  display: block;
  width: 1.44rem;
  height: .56rem;
  line-height: .56rem;
  background: #fff;
  border-radius: .28rem;
  border: .02rem solid #dcaa65;
  box-sizing: border-box;
  margin-top: -.28rem;
  text-align: center;
  position: relative;
  font-size: .24rem;
  color: #DCAA65;
  font-weight: 500;
}
.detail .topUser .right {
  float: left;
  margin-left: .48rem;
}
.detail .topUser .right span {
  display: block;
}
.detail .topUser .name {
  font-size: .40rem;
  line-height: .55rem;
  color: #333;
  font-weight: bold;
}
.detail .topUser .job, .detail .topUser .name {
  max-width: 4.02rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.detail .topUser .job, .detail .topUser .companyName {
  font-size: .26rem;
  line-height: .33rem;
  color: #666;
  margin-top: .255rem;
}
.detail .topUser .companyName {
  max-height: .66rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.detail .button{
  width: 6.7rem;
  height: .96rem;
  background: linear-gradient(135deg,rgba(164,116,55,1) 0%,rgba(220,170,101,1) 100%);
  box-shadow: .04rem .04rem .24rem 0 rgba(88,41,0,0.3);
  border-radius: .1rem;
  position: fixed;
  bottom: .36rem;
  left: .4rem;
  color: #fff;
  font-size: .32rem;
  line-height: .96rem;
  text-align: center;
  z-index: 10;
}
.detail .bottom{
  position: relative;
  padding-top: .48rem;
}
.detail .intro{
  position: relative;
  font-size: .32rem;
  line-height: .42rem;
  background: #fff;
  box-shadow: .04rem .04rem .24rem 0 rgba(51,30,0,0.2);
  border-radius: .1rem;
  overflow: hidden;
}
.detail .bottom > img{
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: .96rem;
  width: .96rem;
  border-radius: 50%;
  border: .06rem solid #fff;
}
.detail .intro .mainTop{
  background:linear-gradient(135deg,rgba(164,116,55,1) 0%,rgba(220,170,101,1) 100%);
  color: #fff;
}
.detail .bottom .header{
  display: flex;
  justify-content: space-around;
  margin-top: .34rem;
  padding-bottom: .3rem;
}
.detail .header .div div{
  height: .96rem;
  width: .96rem;
  background: hsl(0, 0%, 100%, .9);
  border-radius: 50%;
  text-align: center;
}
.detail .header img {
  width: .8rem;
  height: .8rem;
  margin-top: .08rem;
  display: inline-block;
}
.detail .header p{
  display: block;
  margin-top: .08rem;
  font-size: .28rem;
  text-align: center;
  color: #fff;
}
.bottom .mainTop .name{
  display: block;
  text-align: center;
  padding-top: .62rem;
}
.swiper-slide{
  height: 3.94rem;
  position: relative;
}
.swiper-slide img{
  position: absolute;
  top: 50%;
  left: 50%;
  /* max-width: 100%; */
  transform: translate(-50%, -50%);
}
.swiper-button-next, .swiper-button-prev{
  background:hsl(0, 0%, 0%, .4);
  height: .72rem;
  width: .72rem;
  border-radius: 50%;
}
.swiper-container{
  --swiper-theme-color: #fff;
  --swiper-navigation-size: .3rem;
  --swiper-pagination-color: #00ff33; /* 两种都可以 */
}
.detail .demo{
  font-size: .32rem;
  line-height: .42rem;
  position: fixed;
  top: -1000px;
}
.detail .text{
  margin: .32rem .34rem 0 .32rem;
  margin-bottom: .40rem;
  color: #333;
  max-height: 1.26rem;
  overflow: hidden;
}
.detail .mour{
  height: 1.12rem;
  line-height: 1.12rem;
  text-align: center;
  background:#eee;
  border-radius: 0 0 .2rem .2rem;
  color: #D7A561;
  font-size: .28rem;
  margin-top: -.1rem;
  font-weight: bold;
}
.qrImg, .share{
  position: fixed;
  top: 0;
  left: 100%;
  right: 0;
  bottom: 0;
  background: hsla(0, 0%, 0%, 0.3);
  z-index: 10;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.qrImg.active, .share.active{
  left: 0;
}
.qrImg > div{
  background: #fff;
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  border-radius: .2rem;
  overflow: hidden;
  text-align: center;
  transition: all .2s linear;
}
.qrImg.active > div{
  top: 50%;
}
.qrImg div img{
  width: 60%;
  margin: .3rem auto;
}
.qrImg .title {
  color: #dea963;
  background: #f2f2f2;
  font-size: .38rem;
  line-height: .48rem;
  padding: .25rem 0;
  font-weight: bold;
  box-shadow: .04rem .04rem .2rem 0 rgba(126, 126, 126, 0.2);
}
.qrImg .title p{
  font-weight: 400;
}
.qrImg .dec{
  color: #aaa;
  font-size: .28rem;
  line-height: .38rem;
  padding: .25rem 0;
}
.qrImg .bottom{
  background: #dea963;
  color: #fff;
  font-size: .28rem;
  line-height: .38rem;
  padding: .25rem 0 .35rem;
  font-weight: bold;
}
.qrImg .body {
  border-radius: .15rem;
}
.qrImg .body p {
  color: #a0a0a0;
  font-size: .3rem;
  display: block;
  line-height: .38rem;
  padding: .55rem 0;
}
.qrImg .body .footer {
  display: flex;
  border-top: .01rem solid #ececec;
}
.qrImg .body .fBtn {
  flex: 1;
  text-align: center;
  padding: .3rem;
  background: #fff;
  font-size: .32rem;
  line-height: .38rem;
  border: 0;
  border-radius: 0;
}
.qrImg .body .fBtn:first-child{
  color: #999;
  border-right: .01rem solid #ececec;
}
.qrImg .body .fBtn:last-child{
  color: #dba964;
}
.share > div{
  position: absolute;
  left: 0;
  bottom: -100%;
  right: 0;
  background: #fff;
  text-align: center;
  transition: all .2s linear;
}
.share.active > div{
  bottom: 0;
}
.share .cancel{
  height: 1.11rem;
  line-height: 1.11rem;
  font-size: .34rem;
  border-top: .16rem solid #f7f7f7;
}
.share .line{
  display: flex;
}
.share .line div{
  flex: 1;
  text-align: center;
  padding: .44rem 0 .56rem;
}
.share .line img{
  width: 1.04rem;
  height: 1.04rem;
}
.share .line span{
  display: block;
  font-size: .28rem;
  line-height: .38rem;
  color: #666;
  margin-top: .04rem;
}
.tipTo {
  color: #5671CE;
  font-size: .28rem;
  line-height: .40rem;
  text-align: center;
  margin-bottom: .48rem;
  /* margin-left: .74rem; */
}
.tipTo .p {
  color: #DCAA65;
  display: inline;
}
.tipTo img {
  width: .40rem;
  height: .40rem;
  margin-left: .13rem;
  margin-right: .04rem;
  /* float: left; */
}
.tipTo div {
  display: inline;
}
/* .tipTo > * {
  float: left;
} */