.debug{
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.debug div, .wx-btn{
  line-height: 1.1rem;
  height: 1.1rem;
  font-size: .4rem;
  color: #333;
  border: .01rem solid #333;
  /* padding: 0 .4rem; */
  border-radius: .1rem;
  box-shadow: .04rem .04rem .24rem 0 rgba(0, 0, 0, 0.1);
  width: 50%;
  text-align: center;
  
}
.debug div + div{
  margin-top: .4rem;
}
.section{
  background: linear-gradient(135deg,rgba(164,116,55,1) 0%,rgba(220,170,101,1) 100%);
  min-height: 40px;
  min-width: 50px;
}