/* // @font-face {
//   font-family: Akrobat;
//   src: url("../assets/font/Akrobat-ExtraBold.otf");
// } */
html, body, p, h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
  font-size: 13px;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: #409eff;
  -webkit-tap-highlight-color: transparent;
}

div, section, aside, ul, li, header,input,main,button,a,img,nav {
  box-sizing: border-box;
}
img{
  vertical-align: top;
}
i,strong{
  font-style: normal;
}
/* // 清除浮动 */
.clear:after {
  content: '';
  display: block;
  clear: both;
}
.box{
  width: 7.5rem;
  /* // padding:0 .24rem;
  // margin-top:.88rem; */
}
i{
  font-style: normal;
}
strong{
  font-weight: normal;
}
a{
  cursor: pointer;
}